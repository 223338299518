import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MotionListDesc from '@/components/atoms/motionListDesc';

const MotionListParent = ({
        header, 
        headerIcon, 
        headerIconColor, 
        listTitleColor, 
        listTitleIcon, 
        listTitleIconColor, 
        remarksIcon, 
        remarksIconColor, 
        data
    }) => {

    const list = {
        visible: {
          opacity: 1,
          transition: { when: "beforeChildren", staggerChildren: 0.4, bounce: 0.2 }
        },
        hidden: { opacity: 0 }
      };

    return (
        <div className='m-2 text-lg'>
            <motion.h1 className='m-2 border-b-2 inline-block border-indigo-600 px-4 font-extrabold'>
                <FontAwesomeIcon icon={headerIcon} className={`${headerIconColor} mr-2`} /> 
                    {header}
                <FontAwesomeIcon icon={headerIcon} className={`${headerIconColor} ml-2`} />
            </motion.h1>
            <motion.ul initial="hidden" animate="visible" variants={list} className='px-2' >
                {
                data.allFile.nodes.map(node => (
                    <MotionListDesc 
                        title={node.childMdx.frontmatter.title}
                        text={node.childMdx.body}
                        remarks={node.childMdx.frontmatter.remark}
                        titleColor={listTitleColor}
                        titleIcon={listTitleIcon}
                        titleIconColor={listTitleIconColor}
                        remarksIcon={remarksIcon}
                        remarksIconColor={remarksIconColor}
                        key={node.childMdx.frontmatter.idx} 
                    />
                ))
                }
            </motion.ul>
        </div>
    )
}

export default MotionListParent