import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import InnerLinkIcon from './atoms/innerLinkIcon'
import InnerLinkListItem from './atoms/innerLinkListItem'
import ArticleTemp from './modules/articleTemp'

const SouzokuStart = () => {
    return (
        <ArticleTemp
            header={`相続登記はお早めに`}
            descText={`何から始めたら良いか分からない方は、まずは下記よりお気軽にご相談ください。`}
        >
            <div className='w-1/3 mx-auto flex justify-center items-center my-10 p-2 border rounded-md hover:bg-indigo-900 hover:text-red-500'>
                <InnerLinkIcon to={"/contact"} text={"お問い合わせ"} icon={faLink} />
            </div>
        </ArticleTemp>
    )
}

export default SouzokuStart
