import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';

const InnerLinkIcon = ({to, text, icon}) => {
    return (
        <motion.div whileHover={{scale: 0.9, opacity: 0.8}} whileTap={{scale: 1.5, opacity: 0.8}}>
            <Link to={to} className='text-center text-lg'>    
                <FontAwesomeIcon icon={icon} className={`mr-2`} />
                <span>{text}</span>
            </Link>
        </motion.div>
      );;
};

export default InnerLinkIcon;
