import React, { useState } from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby';

const Accordion = ({question, answer, questionTextColor, questionIcon, questionIconColor, link, linkText }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <motion.div className='my-4 shadow-sm'>
            <AnimatePresence>
                <motion.div
                    key="question"
                    className="relative px-1 py-2 text-lg cursor-pointer rounded bg-gray-300"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <motion.div className={`${questionTextColor} font-extrabold mx-3 ml-4 flex justify-between`}>
                        <div>
                            { question }
                            <FontAwesomeIcon icon={questionIcon} className={`${questionIconColor} ml-2`}/>
                        </div>
                        <div className='mt-auto'>
                            <FontAwesomeIcon icon={faArrowAltCircleDown}/>
                        </div>       
                    </motion.div>
                </motion.div>

                {isOpen && (
                    <motion.div
                        key="answer"
                        initial={{ opacity: 0 }}
                        animate={{
                        opacity: 1,
                        transition: {
                            duration: .6,
                        },
                        }}
                        exit={{ opacity: 0 }}
                        className="p-2 text-lg text-gray-600 border-x border-b border-gray-300 rounded-b leading-relaxed"
                    >
                        <MDXRenderer>
                            { answer }
                        </MDXRenderer>
                        { (link != '' && linkText != '') && (
                            <div className='text-right text-indigo-700 my-4'>
                                <Link to={`${link}`} className='border-b border-indigo-700'>
                                    <FontAwesomeIcon icon={faLink} className='mr-2' />
                                    {linkText}
                                </Link>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}
        

export default Accordion