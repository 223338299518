import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MotionListDesc = ({
        title, text, remarks, titleColor, titleIcon, titleIconColor, remarksIcon, remarksIconColor
    }) => {
    const item = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
    };
    
    return (
        <motion.li variants={item} className='m-1 my-4'>
            <FontAwesomeIcon icon={titleIcon} className={`${titleIconColor} mr-2`} />  
                <span className={`font-extrabold ${titleColor}`}>{ title }</span>
                 <br/>
            <MDXRenderer>
                { text }
            </MDXRenderer>
            <div className='my-2'>
                <motion.span animate={{opacity: [0.2, 1, 1, 0.2]}} transition={{ duration: 1, repeat: Infinity}} className='z-50 bg-white w-full'>
                    <FontAwesomeIcon icon={remarksIcon} className={`${remarksIconColor} mr-2`}/>
                </motion.span>
                { remarks }               
            </div>
        </motion.li>
    )
}

export default MotionListDesc