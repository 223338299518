import React from 'react';
import Layout from '@/components/Layout';
import SouzokuGimuArticle from '@/components/souzokuGimuArticle';
import SouzokuToki from '@/components/souzokuToki';
import SouzokuStart from '@/components/souzokuStart';
import SEO from '@/components/seo';

export default function Home() {

  return (
    <Layout>
      <SEO title={``} description={``}/>
      <SouzokuGimuArticle />
      <SouzokuToki />
      <SouzokuStart />
    </Layout>
  );
}
