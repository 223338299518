import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { faExclamation, faLightbulb } from "@fortawesome/free-solid-svg-icons"
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons"
import ArticleTemp from './modules/articleTemp'
import MotionListParent from './blocks/motionListParent'

const ARTICLE = {
    header: `相続登記法改正の内容は？`,
    descText: `以下、具体的な義務化の内容をご紹介します。義務違反の場合は、過料と言われる反則金を支払うことになる規定も盛り込まれていますので、ご注意ください。`,
}

const SouzokuGimuArticle = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
              filter: {sourceInstanceName: {eq: "kaiseiNaiyoMd"}}
              sort: {fields: childMdx___frontmatter___idx, order: ASC}
            ) {
              nodes {
                childMdx {
                  frontmatter {
                    idx
                    remark
                    title
                  }
                  body
                }
              }
            }
          }`)

    return (
        <div className='bg-gray-50'>
            <ArticleTemp
                header={ARTICLE.header} 
                descText={ARTICLE.descText}
                >
                <MotionListParent 
                    header={'主な改正内容'}
                    headerIcon={faExclamation}
                    headerIconColor={`text-red-500`}
                    listTitleColor={`text-red-600`}
                    listTitleIcon={faCheckSquare}
                    listTitleIconColor={`text-indigo-600`}
                    remarksIcon={faLightbulb}
                    remarksIconColor={`text-yellow-500`}
                    data={data} />
            </ArticleTemp>
        </div>
    )
}

export default SouzokuGimuArticle