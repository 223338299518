import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import Accordion from './atoms/accordion'
import ArticleTemp from './modules/articleTemp'

const ARTICLE = {
    header: `相続登記義務化とは？`,
    descText: `令和6年4月1日より、相続登記等が義務化されます！正当な理由なく義務に違反した場合は過料の制裁がありますので、ご注意ください。
    以下、Ｑ＆Ａ形式で基本的な相続登記のご紹介をします。`,
}

const SouzokuToki = () => {

    const data = useStaticQuery(graphql`
    query {
        allFile(
          filter: {sourceInstanceName: {eq: "souzokuQandA"}}
          sort: {fields: childMdx___frontmatter___qaidx, order: ASC}
        ) {
          nodes {
            childMdx {
              frontmatter {
                qaidx
                qatitle
                qalink
                qalinkText
              }
              body
            }
          }
        }
      }
    `)

    return (
        <ArticleTemp 
            header={ARTICLE.header}
            descText={ARTICLE.descText}
        >
            {
                data.allFile.nodes.map(node => (
                    <Accordion 
                        question={node.childMdx.frontmatter.qatitle}
                        answer={node.childMdx.body}
                        questionTextColor={`text-blue-900`}
                        questionIcon={faQuestion}
                        questionIconColor={`text-red-600`}
                        link={node.childMdx.frontmatter.qalink}
                        linkText={node.childMdx.frontmatter.qalinkText}
                        key={node.childMdx.frontmatter.qaidx}
                    />
                ))
            }
            
        </ArticleTemp>
    )
}

export default SouzokuToki